.l-container{
    width: 100vw;
    height: 100vh;
   
    position: fixed;
   
}
.login-container{
    width: 70vw;
    height: 80vh;
    
    margin-left: 15vw;
    margin-top: 10vh;
   
}
.background-img{
    width: 70vw;
    height: 80vh;
    opacity: 0.9;
}
.login-form{
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 70vh;left: 300px;
    border: 1px solid black;
    width: 500px;
    height: 320px;
    
   
    
}
.login-form label{
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
    margin-left: 50px;
}
.login-form  input{
    width: 400px;
    height: 30px;
    font-size: 15px;
    margin-left: 50px;
    border-radius: 5px;
    border: 1px solid black;
}
.login-form p{
    font-weight: 500;
    margin-left: 300px;
}
.btns{
    display: flex;
    justify-content: space-between;
}
.login-btn,.signup-btn{
    width: 170px;
    height: 30px;
    
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 20px;
}
.login-btn{
    /* background-color: aqua; */
  
    margin-left: 50px;
}
.signup-btn{
    margin-right: 50px;
    background-color: rgb(43, 128, 219);
   
}